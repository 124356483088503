import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImageGenerator from './Components/ImageGenerator/ImageGenerator';
import LandingPage from './Components/LandingPage/LandingPage';
import PaymentSuccess from './Components/PaymentSuccess/PaymentSuccess'; 
import PaymentFailure from './Components/PaymentFailure/PaymentFailure'; 


function App() {
//   const [showImageGenerator, setShowImageGenerator] = useState(false);

//   return (
//     <div>
//       {!showImageGenerator ? (
//         <LandingPage onCreateShirt={() => setShowImageGenerator(true)} />
//       ) : (
//         <ImageGenerator />
//       )}
//     </div>
//   );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/design" element={<ImageGenerator />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-cancelled" element={<PaymentFailure />} />
      </Routes>
    </Router>
  );

}

export default App;
