// Helper functions for managing images in sessionStorage

// Save an image to sessionStorage
export const saveImageToStorage = (imageUrl, promptText) => {
  if (!imageUrl || !promptText) {
    console.error('Missing required data for saving image');
    return;
  }
  
  try {
    // Get existing images from sessionStorage
    const storedImages = JSON.parse(sessionStorage.getItem("generatedImages")) || [];
    
    // Create a new image object
    const newImage = {
      image_id: Date.now().toString(), // Use timestamp as a simple unique ID
      image_url: imageUrl,
      prompt: promptText,
      created_at: new Date().toISOString()
    };
    
    // Add the new image to the array
    storedImages.push(newImage);
    
    // Save the updated array back to sessionStorage
    sessionStorage.setItem("generatedImages", JSON.stringify(storedImages));
    
    console.log('Saved image to session storage:', newImage);
    
    return storedImages;
  } catch (error) {
    console.error('Error saving image to session storage:', error);
    return [];
  }
};

// Get all saved images from sessionStorage
export const getSavedImages = () => {
  try {
    return JSON.parse(sessionStorage.getItem("generatedImages")) || [];
  } catch (error) {
    console.error('Error retrieving images from session storage:', error);
    return [];
  }
};

// Clear all saved images from sessionStorage
export const clearSavedImages = () => {
  try {
    sessionStorage.removeItem("generatedImages");
    console.log('Cleared all saved images from session storage');
    return true;
  } catch (error) {
    console.error('Error clearing images from session storage:', error);
    return false;
  }
}; 