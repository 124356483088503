import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import SlickCarousel from "../Carousel/SlickCarousel.jsx";
// import sample1 from '../Assets/sample1.png';
import step1Image from '../Assets/Step1-8.png'; // Import your step images
import step2Image from '../Assets/Step2-10-rmbg.png';
import step3Image from '../Assets/Step3-1.png';
import step2color from '../Assets/step2color-rmbg.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
        <header className="logo-header">
            {/* <h1 className="logo-text">Bluedove</h1> */}
            <Link to="/" style={{ textDecoration: "none" }}>
                <h1 className="logo-text">Bluedove</h1>
            </Link>
        </header>

        <div className="landing-container">
            <div className="left-column">
                <h1>Transform your imagination into fashion</h1>
                <p>
                    Create unique T-shirts easily with Bluedove.
                    The AI-powered, end-to-end custom apparel solution.
                </p>

                <div className="action-button">
                    <Link to="/design">
                    <button className="landing-button">
                        Create Now <span className="arrow">&rarr;</span>
                    </button>
                    </Link>
                </div>
            </div>

            <div className="right-column">
            <SlickCarousel />
            </div>
        </div>

        <div className="benefits-row">
            <div className="benefit-item">
                <span className="check-icon">✔</span>
                Design to Delivery, All in One Place
            </div>
            <div className="benefit-item">
                <span className="check-icon">✔</span>
                No hassle, just Easy and Fun
            </div>   
            <div className="benefit-item">
                <span className="check-icon">✔</span>
                No design skills needed, AI will do
            </div> 
        </div>

        <div className="how-it-works-container">
            <h1>Make Your T-Shirts Your Own</h1>
            <div className="how-it-works-box">
            
                <div className="step step1">
                    <div className="step-number">1</div>

                    <h2 className="step-title">You Create</h2>

                    {/* <h2>1. You Create</h2> */}
                    <p className="step-desc">Describe your idea, and our AI instantly turns it into a design.</p>
                    {/* <img src={step1Image} alt="Create Your Design" className="step-image" /> */}
                    
                    <div className="rect-bg">         
                        <div className="text-box">
                            {/* <p> */}
                            A typography with "The best is coming" in light and 
                            bright pink font. 
                            {/* Background removed. */}
                            {/* for vintage T-shirt  */}
                            {/* </p> */}
                        </div>
                        <div className="image-container">
                        <img src={step1Image} alt="Create Your Design" className="step-image" />
                        </div>
                    </div>
                </div>

                <div className="step step2">
                    <div className="step-number">2</div>
                    {/* <h2>2. You Choose</h2> */}
                    <h2 className="step-title">You Choose</h2>
                    <p className="step-desc">Pick your T-shirt style and color, then place your order online.</p>
                    {/* <img src={step2Image} alt="Choose T-shirt Color and Size" className="step-image" /> */}
                    
                    <div className="rect-bg">
                        <div className="image-container">
                        <img src={step2color} alt="Choose T-shirt Color and Size" className="step-color" />
                        <img src={step2Image} alt="Choose T-shirt Color and Size" className="step-image" />
                        </div>
                    </div>
                </div>

                <div className="step step3">
                    <div className="step-number">3</div>
                    {/* <h2>3. We Handle the Rest</h2> */}
                    <h2 className="step-title">We Handle the Rest</h2>
                    <p className="step-desc">Bluedove prints and delivers your custom T-shirt straight to your door.</p>
                    {/* <img src={step3Image} alt="We Deliver Your T-Shirts" className="step-image" /> */}
                    <div className="rect-bg">
                        <div className="image-container">
                        <img src={step3Image} alt="We Deliver Your T-Shirts" className="step-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
};

export default LandingPage;
