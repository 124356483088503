import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './SlickCarousel.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import sample1 from '../Assets/sample1.png';
// import sample2 from '../Assets/sample2.png';
import sample3 from '../Assets/sample3_1.png';
// import sample4 from '../Assets/sample4.png';
import sample5 from '../Assets/sample5.png';
import sample6 from '../Assets/sample6.png';
import sample7 from '../Assets/sample7.png';
import sample8 from '../Assets/sample8.png';
// import sample9 from '../Assets/sample9.png';


function SlickCarousel() {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [sliderVisible, setSliderVisible] = useState(false);

  // Images to preload
  // const carouselImages = [sample5, sample8, sample3, sample7, sample6, sample2];
  const carouselImages = [sample5, sample8, sample3, sample7, sample6];

  useEffect(() => {
    // Create an array to track loaded images
    let loadedCount = 0;
    const imagePromises = carouselImages.map(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedCount++;
          // If at least the first two images are loaded, show the slider
          if (loadedCount >= 2 && !sliderVisible) {
            setSliderVisible(true);
          }
          resolve();
        };
        img.onerror = reject;
      });
    });

    // When all images are loaded
    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(error => {
        console.error("Failed to load carousel images:", error);
        // Still show slider even if some images fail to load
        setSliderVisible(true);
      });

    // If images take too long to load, show the slider anyway after 1 second
    const timer = setTimeout(() => {
      if (!sliderVisible) {
        setSliderVisible(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    lazyLoad: 'progressive', // Enable lazy loading
    initialSlide: 0,
  };

  return (
    <div className="carousel-wrapper">
      {!sliderVisible && (
        <div className="carousel-placeholder">
          <div className="loading-spinner"></div>
        </div>
      )}
      
      {sliderVisible && (
        <Slider {...settings}>
          <div>
            <img src={sample5} alt="Slide 5" loading="lazy" />
          </div>
          <div>
            <img src={sample8} alt="Slide 8" loading="lazy" />
          </div>
          <div>
            <img src={sample3} alt="Slide 3" loading="lazy" />
          </div>
          <div>
            <img src={sample7} alt="Slide 7" loading="lazy" />
          </div>
          <div>
            <img src={sample6} alt="Slide 6" loading="lazy" />
          </div>
          {/* <div>
            <img src={sample2} alt="Slide 2" loading="lazy" />
          </div> */}
        </Slider>
      )}
    </div>
  );
}

export default SlickCarousel;
