// import React, {useState, useRef} from 'react';
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Select, { components } from 'react-select';
import './ImageGenerator.css';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { saveImageToStorage, getSavedImages, clearSavedImages } from './SessionStorageHelper';
import './CategoryTabs.css';

import bluedove from '../Assets/bluedovelogo.png';
// import background1 from '../Assets/background1.png';
// import background2 from '../Assets/background2.png';
// import background3 from '../Assets/background3.png';
// import background4 from '../Assets/background4.png';
// import background5 from '../Assets/background5.png';
import background6 from '../Assets/background6.png';
import whiteTshirt from '../Assets/tshirt_white_rmbg.png';
import blackTshirt from '../Assets/tshirt_black_rmbg.png';
import darkgrayTshirt from '../Assets/tshirt_darkgray_rmbg.png';
import mediumblueTshirt from '../Assets/tshirt_mediumblue_rmbg.png';
import brickredTshirt from '../Assets/tshirt_brickred_rmbg.png';
import beanpasteTshirt from '../Assets/tshirt_beanpaste_rmbg.png';
import graygreenTshirt from '../Assets/tshirt_graygreen_rmbg.png';
import lightgrayTshirt from '../Assets/tshirt_lightgray_rmbg.png';
import lightapricotTshirt from '../Assets/tshirt_lightapricot_rmbg.png';
import rainwashedTshirt from '../Assets/tshirt_rainwashed_rmbg.png';
import brownTshirt from '../Assets/tshirt_brown_rmbg.png';


// Add your image assets for visuals (optional)
import sampleDesign1 from '../Assets/sampleDesign1.png'; 
import sampleDesign2 from '../Assets/sampleDesign2.png';
import sampleDesign3 from '../Assets/sampleDesign3.png'; 
import sampleDesign4 from '../Assets/sampleDesign4.png';
import sampleDesign5 from '../Assets/sampleDesign5.png';
import sampleDesign6 from '../Assets/sampleDesign6.png';
// import sampleDesign7 from '../Assets/sampleDesign7.png';
// import sampleDesign8 from '../Assets/sampleDesign8.png';
// import sampleDesign9 from '../Assets/sampleDesign9.png';
// import sampleDesign10 from '../Assets/sampleDesign10.png';
// import sampleDesign11 from '../Assets/sampleDesign11.png';
// import sampleDesign12 from '../Assets/sampleDesign12.png';
// import sampleDesign13 from '../Assets/sampleDesign13.png';
// import sampleDesign14 from '../Assets/sampleDesign14.png';
// import sampleDesign15 from '../Assets/sampleDesign15.png';
// import sampleDesign16 from '../Assets/sampleDesign16.png';
// import sampleDesign17 from '../Assets/sampleDesign17.png';
// import sampleDesign18 from '../Assets/sampleDesign18.png';
// import sampleDesign19 from '../Assets/sampleDesign19.png';
// import sampleDesign20 from '../Assets/sampleDesign20.png';
// import sampleDesign21 from '../Assets/sampleDesign21.png';
// import sampleDesign22 from '../Assets/sampleDesign22.png';
// import sampleDesign23 from '../Assets/sampleDesign23.png';
// import sampleDesign24 from '../Assets/sampleDesign24.png';
// import sampleDesign25 from '../Assets/sampleDesign25.png';
// import sampleDesign26 from '../Assets/sampleDesign26.png';
// import sampleDesign27 from '../Assets/sampleDesign27.png';
// import sampleDesign28 from '../Assets/sampleDesign28.png';
// import sampleDesign29 from '../Assets/sampleDesign29.png';
// import sampleDesign30 from '../Assets/sampleDesign30.png';
// import sampleDesign31 from '../Assets/sampleDesign31.png';
// import sampleDesign32 from '../Assets/sampleDesign32.png';
// import sampleDesign33 from '../Assets/sampleDesign33.png';
// import sampleDesign34 from '../Assets/sampleDesign34.png';
// import sampleDesign35 from '../Assets/sampleDesign35.png';
// import sampleDesign36 from '../Assets/sampleDesign36.png';
// import sampleDesign37 from '../Assets/sampleDesign37.png';
// import sampleDesign38 from '../Assets/sampleDesign38.png';
// import sampleDesign39 from '../Assets/sampleDesign39.png';
// import sampleDesign40 from '../Assets/sampleDesign40.png';
// import sampleDesign41 from '../Assets/sampleDesign41.png';
import sampletshirt1 from '../Assets/sampletshirt1.png';
import sampletshirt2 from '../Assets/sampletshirt2.png';
import sampletshirt5 from '../Assets/sampletshirt5.png';
import sampletshirt6 from '../Assets/sampletshirt6.png';
// import sampletshirt7 from '../Assets/sampletshirt7.png';
import sampletshirt8 from '../Assets/sampletshirt8.png';
// import sampletshirt9 from '../Assets/sampletshirt9.png';
import sampletshirt10 from '../Assets/sampletshirt10.png';
import sampletshirt11 from '../Assets/sampletshirt11.png';
import sampletshirt12 from '../Assets/sampletshirt12.png';
import sampletshirt13 from '../Assets/sampletshirt13.png';
import sampletshirt14 from '../Assets/sampletshirt14.png';
import sampletshirt17 from '../Assets/sampletshirt17.png';
import sampletshirt18 from '../Assets/sampletshirt18.png';

// Example templates for Artistic Images
const artisticExamples = [
  // { id: 1, image: sampleDesign26, label: "Artistic Example 1",
  //   prompt: 
  //   Subject: an illustration of pilates pose called 'Downward Dog' with a cute dog
  //   Details: The dog is balanced on its hind legs and is holding on a light pink yoga mat 
  //   Background Color: white
  //   Text: Paws & Reflect: Pilates Dog at Peace
  //   Text Position: Below the image
  //   Additional Description: There is a light pink heart above the dog's head,
  //  },
  // { id: 2, image: sampleDesign29, label: "Artistic Example 2",
  //   prompt: 
  //   Subject: a cute, hand-drawn cartoon character
  //   Details: wearing a party hat and surrounded by confetti 
  //   Background Color: white
  //   Text: Celebrate Every Day
  //   Text Position: beneath the character
  //   Font: a playful, rounded font
  //   Additional Description: The character has big eyes and is wearing a red outfit.,
  //  },

  { id: 1, image: sampletshirt1, label: "Artistic Example 1",
    prompt: `
    Subject: A realistic and minimalist monochrome butterfly with soft shading and high contrast
    Details: appearing as a grayscale painting or airbrush-style.
    Additional Description: The butterfly's wings are symmetrical, with a smooth, slightly textured surface, and have a subtle gradient from dark edges to a lighter center. 
   `},
   { id: 7, image: sampletshirt11, label: "Artistic Example 7",
    prompt: `
    Subject: A minimalist line-art butterfly in white,
    Details: symmetrically designed with intricate dashed lines on its wings,
    Background Color: black
    Text: Serenity Bloom
    Text Position: slightly above the butterfly, partially overlaying the upper wings of butterfly
    Font: bold serif,
   `},
  { id: 2, image: sampletshirt2, label: "Artistic Example 2",
    prompt: `
    Subject: A playful, hand-drawn blue flower 
    Details: with a smiling face at its center, outlined in black with sketch-like strokes.
    Background Color: white
    Text: WHAT ISN'T 
    Text Position: above the flower, slightly overlapped
    Font: casual, graffiti-style, brushstroke
    Text Color: dark blue
    Additional Description: The petals are filled with a watercolor-style gradient of blue, with small droplets and splashes around them for a fun, slightly messy effect.
   `},
   { id: 4, image: sampletshirt6, label: "Artistic Example 4",
    prompt: `
    Subject: a minimalist and artistic nature-inspired theme
    Details: with an arrangement of delicate, hand-painted wildflowers in pastel shades (yellow, pink, light purple, and white)
    Background Color: sage green
    Text: Seek first His kingdom Matthew 6:33
    Text Position: below the flowers
    Font: a gentle, handwritten appearance to complement the floral artwork
    Text Color: white
    Additional Description: The overall style is calm, uplifting, and modern with a spiritual and natural vibe.,
   `},
   { id: 3, image: sampletshirt5, label: "Artistic Example 3",
    prompt: `
    Subject: A minimalist black line-art drawing of a curious kitten walking towards a ball of yarn. 
    Details: The kitten has a slightly fluffy texture, expressive eyes, and a gentle posture, with its tail slightly raised.
    Background Color: white
    Additional Description: The ball of yarn is blue, with a loose strand extending outward playfully.,
   `},
   { id: 5, image: sampletshirt8, label: "Artistic Example 5",
    prompt: `
    Subject: a retro-themed design 
    Details: with a neon-colored cassette tape set 
    Background Color: black 
    Text: Old School Beats 
    Text Position: above the cassette
    Font: a bubbly retro
    Additional Description: The background has a few stars.
   `},
   { id: 6, image: sampletshirt10, label: "Artistic Example 6",
    prompt: `
    Subject: a retro-style illustration of Paris with the Eiffel Tower as the centerpiece
    Details: The background is a stunning gradient sunset sky that transitions from soft gold to twilight blue
    Background Color: pale cream or light beige
    Text: PARIS
    Text Position: at the bottom
    Font: large, bold, uppercase distressed font
    Text Color: dark blue
    Additional Description: The sky is framed by an arched circle.,
   `},
  // { id: 4, image: sampleDesign32, label: "Artistic Example 4",
  //   prompt: 
  //   Subject: a kawaii-style white coffee cup
  //   Details: with a cute face and rosy cheeks, sitting on a wooden coaster 
  //   Background Color: soft pink
  //   Additional Description: There are little steam lines coming out of the coffee cup, and they curl into hearts.,
  //  },
  // { id: 6, image: sampleDesign6, label: "Artistic Example 6" },
  // { id: 7, image: sampleDesign7, label: "Artistic Example 7" },
  // { id: 8, image: sampleDesign8, label: "Artistic Example 8" },
];

// Example templates for Typography Designs
const typographyExamples = [
  // { id: 9, image: sampleDesign2, label: "Typography Example 1" },
  { id: 8, image: sampletshirt12, label: "Typography Example 1",
    prompt: `
    Text: How deep is your love?
    Font: bold, wavy, playful, and slightly distorted 
    Text Color: red
    Background Color: white
    Additional Description: The text is arranged in a way that roughly forms the shape of a heart, creating a visually striking and expressive composition. ,
   `},
  { id: 9, image: sampletshirt13, label: "Typography Example 2",
    prompt: `
    Text: Stay Positive
    Font: Great Vibes 
    Text Color: pink
    Background Color: black 
    // Additional Description: ,
   `},
  { id: 10, image: sampletshirt17, label: "Typography Example 3",
    prompt: `
    Text: IMAGINE LIFE WITHOUT COFFEE
    Font: Bebas Neue
    Text Color: light brown 
    Background Color: dark brown 
    Additional Description: The word "COFFEE" has a red heart replacing the "O", adding a touch of warmth and affection. There are colorful coffee cup and coffee bean icons. , 
   `},
  // { id: 12, image: sampleDesign17, label: "Typography Example 4" },
  { id: 11, image: sampletshirt14, label: "Typography Example 4",
    prompt: `
    Text: Love
    Font: Great Vibes 
    Text Color: gold
    Background Color: black 
    // Additional Description: 
    // ,
    // Text: "Love",
    // Font: "Great Vibes - flowing and elegant cursive",
    // TextColor: "gold",
    // Background: "black",
    // AdditionalDescription: ""
   `},
  { id: 12, image: sampletshirt18, label: "Typography Example 5",
    prompt: `
    Text: He leads me beside still waters
    Font: Great Vibes 
    Text Color: soft and light gray
    Background Color: light pink or cream-colored
    Additional Text: He restores my soul
    Additional Text Position: beneath the first text
    Additional Text Font: Bebas Neue
    Additional Text Color: soft and light gray
    Additional Description: subtle and peaceful tone, evoking a serene and spiritual feeling,
   `},
  // { id: 14, image: sampleDesign14, label: "Typography Example 6" },
  // { id: 15, image: sampleDesign15, label: "Typography Example 7" },
  // { id: 16, image: sampleDesign16, label: "Typography Example 8" },
];

// 1. Define the array of font options outside the component
const fontOptions = [
  { value: 'Arial', label: 'Arial - Clean and Modern Sans-Serif', fontFamily: 'Arial, sans-serif' },
  // { value: 'Helvetica', label: 'Helvetica', fontFamily: 'Helvetica, sans-serif' },
  { value: 'Times New Roman', label: 'Times New Roman - classic and formal serif', fontFamily: '"Times New Roman", Times, serif' },
  // { value: 'Courier New', label: 'Courier New', fontFamily: '"Courier New", Courier, monospace' },
  // { value: 'Verdana', label: 'Verdana', fontFamily: 'Verdana, Geneva, sans-serif' },
  { value: 'Bebas Neue', label: 'Bebas Neue - all-caps and bold sans-serif', fontFamily: '"Bebas Neue", sans-serif' },
  { value: 'Montserrat', label: 'Montserrat - bold and contemporary sans-serif', fontFamily: 'Montserrat, sans-serif' },
  { value: 'Playfair Display', label: 'Playfair Display - high-contrast and stylish serif', fontFamily: '"Playfair Display", serif' },
  { value: 'Lobster', label: 'Lobster - decorative and bold cursive', fontFamily: 'Lobster, cursive' },
  // { value: 'Pacifico', label: 'Pacifico - casual and cursive', fontFamily: 'Pacifico, cursive' },
  // { value: 'Georgia', label: 'Georgia - elegant and traditional serif', fontFamily: 'Georgia, serif' },
  // { value: 'Roboto', label: 'Roboto', fontFamily: 'Roboto, sans-serif' },
  // { value: 'Open Sans', label: 'Open Sans', fontFamily: '"Open Sans", sans-serif' },
  // { value: 'Lato', label: 'Lato', fontFamily: 'Lato, sans-serif' },
  // { value: 'Raleway', label: 'Raleway', fontFamily: 'Raleway, sans-serif' },
  { value: 'Great Vibes', label: 'Great Vibes - flowing and elegant cursive', fontFamily: 'Great Vibes, cursive' },
  // { value: 'sans-serif', label: 'Sans-Serif', fontFamily: 'sans-serif' },
  // { value: 'serif', label: 'Serif', fontFamily: 'serif' },
  // { value: 'bauhaus', label: 'Bauhaus', fontFamily: 'sans-serif' }, // Using sans-serif as a placeholder
];

const fontStyleMapping = {
  'Arial': 'clean and modern sans-serif',
  // 'Helvetica': 'neutral and widely-used sans-serif',
  'Times New Roman': 'classic and formal serif',
  // 'Courier New': 'monospaced and typewriter-style',
  // 'Verdana': 'wide and highly-legible sans-serif',
  'Bebas Neue': 'all-caps and bold sans-serif',
  'Montserrat': 'bold and contemporary sans-serif',
  'Playfair Display': 'high-contrast and stylish serif',
  'Lobster': 'decorative and bold cursive',
  // 'Pacifico': 'casual and cursive',
  // 'Georgia': 'elegant and traditional serif',
  // 'Roboto': 'geometric and friendly sans-serif',
  // 'Open Sans': 'simple and open sans-serif',
  // 'Lato': 'rounded and warm sans-serif',
  // 'Raleway': 'elegant and thin sans-serif',
  'Great Vibes': 'flowing and elegant cursive',
  // 'sans-serif': 'a clean and modern sans-serif style',
  // 'serif': 'a classic and formal serif style',
  // 'bauhaus': 'a bold and geometric Bauhaus style',
};

// Define Design Type options
const designTypeOptions = [
  { value: 'An image of', label: 'An image of' },
  { value: 'A design of', label: 'An design of' },
  { value: 'A typography design', label: 'A typography design' },
  // { value: 'An illustration of', label: 'An illustration of' },
  // { value: 'A 3D rendering of', label: 'A 3D rendering of' },
  // { value: 'A drawing of', label: 'A drawing of' },
  // { value: 'A picture of', label: 'A picture of' },
  // { value: 'A photograph of', label: 'A photograph of' },
  // { value: 'A sketch of', label: 'A sketch of' },
];
//https://docs.ideogram.ai/using-ideogram/ideogram-features/style
// CustomOption.js (optional file) or within ImageGenerator.js

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ fontFamily: props.data.fontFamily }}>
        {props.label}
      </div>
    </components.Option>
  );
};

const gridImages = [
  { id: 1, image: sampleDesign1, label: "Grid Image 1", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},
  { id: 2, image: sampleDesign2, label: "Grid Image 2", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},
  { id: 3, image: sampleDesign3, label: "Grid Image 3", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},
  { id: 4, image: sampleDesign4, label: "Grid Image 4", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},
  { id: 5, image: sampleDesign5, label: "Grid Image 5", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},
  { id: 6, image: sampleDesign6, label: "Grid Image 6", 
    designType: "Typography Design",
    text: "Stay Positive",
    font: "Bold Sans-Serif",
    textColor: "Black",
    backgroundColor: "White"},

    { id: 10, image: sampleDesign5, label: "Grid Image 10", 
      designType: "Typography Design",
      text: "Stay Positive",
      font: "Bold Sans-Serif",
      textColor: "Black",
      backgroundColor: "White"},
    { id: 11, image: sampleDesign6, label: "Grid Image 11", 
      designType: "Typography Design",
      text: "Stay Positive",
      font: "Bold Sans-Serif",
      textColor: "Black",
      backgroundColor: "White"},
    { id: 12, image: sampleDesign5, label: "Grid Image 12", 
      designType: "Typography Design",
      text: "Stay Positive",
      font: "Bold Sans-Serif",
      textColor: "Black",
      backgroundColor: "White"},
    { id: 13, image: sampleDesign6, label: "Grid Image 13", 
      designType: "Typography Design",
      text: "Stay Positive",
      font: "Bold Sans-Serif",
      textColor: "Black",
      backgroundColor: "White"}
];

const usZipRegex = /^\d{5}(-\d{4})?$/;

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,  // Show 3 slides at once on desktop
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: true,
  // responsive: [
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       arrows: false,
  //     }
  //   }
  // ]
};

   // A small helper function to extract lines from your multi-line prompt
function parsePromptIntoFields(promptString) {
  const subjectMatch = promptString.match(/Subject:\s*(.*)/i);
  const detailsMatch = promptString.match(/Details:\s*(.*)/i);
  const bgColorMatch = promptString.match(/Background Color:\s*(.*)/i);
  // const textIfAnyMatch = promptString.match(/Text if any:\s*(.*)/i);
  const textMatch = promptString.match(/Text:\s*(.*)/i);
  const textPosMatch = promptString.match(/Text Position:\s*(.*)/i);

  const fontMatch = promptString.match(/Font:\s*(.*)/i);
  const fontColorMatch = promptString.match(/Text Color:\s*(.*)/i);

  const extratextMatch = promptString.match(/Additional Text:\s*(.*)/i);
  const extratextPosMatch = promptString.match(/Additional Text position:\s*(.*)/i);
  const extrafontMatch = promptString.match(/Additional Text Font:\s*(.*)/i);
  const extrafontColorMatch = promptString.match(/Additional Text Color:\s*(.*)/i);

  const additionalMatch = promptString.match(/Additional Description:\s*(.*)/i);

  return {
    subject: subjectMatch ? subjectMatch[1].trim() : '',
    details: detailsMatch ? detailsMatch[1].trim() : '',
    backgroundColor: bgColorMatch ? bgColorMatch[1].trim() : '',
    text: textMatch ? textMatch[1].trim() : '',
    textPosition: textPosMatch ? textPosMatch[1].trim() : '',
    font: fontMatch ? fontMatch[1].trim() : '',
    fontColor: fontColorMatch ? fontColorMatch[1].trim() : '',

    extraText: extratextMatch ? extratextMatch[1].trim() : '',
    extraTextPosition: extratextPosMatch ? extratextPosMatch[1].trim() : '',
    extraFont: extrafontMatch ? extrafontMatch[1].trim() : '',
    extraFontColor: extrafontColorMatch ? extrafontColorMatch[1].trim() : '',

    additional: additionalMatch ? additionalMatch[1].trim() : '',
  };
}

// Add this before the component definition
const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const preloadImagesInBackground = (images) => {
  images.forEach(image => {
    // Use requestIdleCallback for non-blocking background loading
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        preloadImage(image);
      });
    } else {
      // Fallback for browsers that don't support requestIdleCallback
      setTimeout(() => {
        preloadImage(image);
      }, 1);
    }
  });
};

const ImageGenerator = () => {

  const [image_url, setImage_url] = useState("/")
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [loading, setLoading] = useState(false);
  const [bgLoading, setBgLoading] = useState(false); // Loading state for background removal
  const [generatedImage, setGeneratedImage] = useState(null);
  const [finalImage , setFinalImage] = useState(null); // State for final saved image
  const [tshirtColor, setTshirtColor] = useState('white'); // Changed initial state to null
  const [page, setPage] = useState('hasBoth'); // State to manage different pages
  const [history, setHistory] = useState([]); // Stack to keep track of page history
  const [imageType, setImageType] = useState('');
  
  // Session management state variables
  const [sessionId, setSessionId] = useState(localStorage.getItem('tempSessionId') || '');
  const [sessionExpiry, setSessionExpiry] = useState(localStorage.getItem('tempSessionExpiry') || '');
  const [savedImages, setSavedImages] = useState([]);
  const [showSavedImages, setShowSavedImages] = useState(false);

  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('');

  const [text, setText] = useState('');
  const [textposition, setTextPosition] = useState('');
  const [font, setFont] = useState('');
  const [fontColor, setFontColor] = useState('');

  const [extraText, setExtraText] = useState('');
  const [extraTextPosition, setExtraTextPosition] = useState('');
  const [extraFont, setExtraFont] = useState('');
  const [extraFontColor, setExtraFontColor] = useState('');
  
  const [additional, setAdditional] = useState('');


  const [prompt, setPrompt] = useState('');
  const [feedback, setFeedback] = useState('');
  const [tshirtSize, setTshirtSize] = useState(null); // Initial state for size selection

  // Order form states
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [finalPrompt, setFinalPrompt] = useState('');
  const [orderId, setOrderId] = useState('');
  const [designType, setDesignType] = useState(null);

   // 2) Popup (Modal) State for samples
   const [showModal, setShowModal] = useState(false);
   const [selectedDesign, setSelectedDesign] = useState(null);

   const [originalPrompt, setOriginalPrompt] = useState('');

  // Add this near the top of the file where other state variables are defined
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [visibleCarousel, setVisibleCarousel] = useState(false);

  // Add this useEffect near other useEffect hooks
  useEffect(() => {
    // Set carousel to visible immediately, without a delay
    setVisibleCarousel(true);
    
    // Mark carousel as fully loaded after reasonable timeout
    const loadedTimer = setTimeout(() => {
      setCarouselLoaded(true);
    }, 1000);

    return () => {
      clearTimeout(loadedTimer);
    };
  }, []);

  // Create a temporary session when the component mounts
  useEffect(() => {
    // Check if we already have a valid session
    const storedSessionId = localStorage.getItem('tempSessionId');
    const storedSessionExpiry = localStorage.getItem('tempSessionExpiry');
    
    if (storedSessionId && storedSessionExpiry) {
      // Check if the session is still valid
      const expiryDate = new Date(storedSessionExpiry);
      if (expiryDate > new Date()) {
        // Session is still valid, use it
        setSessionId(storedSessionId);
        setSessionExpiry(storedSessionExpiry);
        // Load saved images for this session
        fetchSessionImages(storedSessionId);
        return;
      }
    }
    
    // No valid session, create a new one
    createNewSession();
  }, []);

  // Load saved images from sessionStorage when component mounts
  useEffect(() => {
    // Get saved images from sessionStorage
    const storedImages = getSavedImages();
    setSavedImages(storedImages);
  }, []);

  // Add image preloading useEffect hook 
  useEffect(() => {
    // Preload first few images from each category to speed up initial display
    const criticalImages = [
      ...artisticExamples.slice(0, 3).map(item => item.image),
      ...typographyExamples.slice(0, 3).map(item => item.image)
    ];
    
    // Start preloading critical images right away
    Promise.all(criticalImages.map(preloadImage))
      .then(() => {
        // After critical images are loaded, preload the rest in background
        const remainingImages = [
          ...artisticExamples.slice(3).map(item => item.image),
          ...typographyExamples.slice(3).map(item => item.image)
        ];
        preloadImagesInBackground(remainingImages);
      })
      .catch(error => {
        console.error('Error preloading images:', error);
      });
  }, []); // Empty dependency array since artisticExamples and typographyExamples are static

  // Add tab styling useEffect hook
  // useEffect(() => {
  //   // Force style update when page changes using class toggling instead of direct style manipulation
  //   const imagesTab = document.querySelector('.category-tab:first-child');
  //   const typographyTab = document.querySelector('.category-tab:last-child');
    
  //   if (imagesTab && typographyTab) {
  //     if (page === 'hasBoth') {
  //       imagesTab.classList.add('active');
  //       typographyTab.classList.remove('active');
  //     } else if (page === 'hasText') {
  //       typographyTab.classList.add('active');
  //       imagesTab.classList.remove('active');
  //     }
  //   }
  // }, [page]);

  //  const [showSamples, setShowSamples] = useState(true);
 
   // Example: handle click on a sample
   const handleImageClick = (design) => {
    //  setImage_url(design.image);
     
     setSelectedDesign(design);
     setShowModal(true);

     setOriginalPrompt(design.prompt);

   // 1) Parse the prompt fields
    const parsedFields = parsePromptIntoFields(design.prompt);
    // 2) Set your local states
    setSubject(parsedFields.subject);
    setDetails(parsedFields.details);
    setbackgroundColor(parsedFields.backgroundColor);
    setText(parsedFields.text);
    setTextPosition(parsedFields.textPosition);
    setFont(parsedFields.font);
    setFontColor(parsedFields.fontColor);
    setExtraText(parsedFields.extraText);
    setExtraTextPosition(parsedFields.extraTextPosition);
    setExtraFont(parsedFields.extraFont);
    setExtraFontColor(parsedFields.extraFontColor);
    setAdditional(parsedFields.additional);

    // 3) Also set your prompt and inputValue to the entire text,
    //    so your final <textarea> sees it immediately.
    // setPrompt(design.prompt);
    setInputValue(design.prompt);

    // If you want to jump to the "hasBoth" page automatically:
  //  setPage('hasBoth');

   };

   function handleCloseModal() {
    setShowModal(false);
    setSelectedDesign(null);
  }

  function MyCarousel() {
    return (
      <Slider {...settings}>
        {artisticExamples.map((item) => (
          <div key={item.id} 
          className="carousel-slide clickable-slide" 
          onClick={() => handleImageClick(item)}
          style={{ 
            margin: '0 8px',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            position: 'relative',
            cursor: 'pointer'
          }}
          >
            <img 
              src={item.image} 
              alt={item.label} 
              loading="lazy"
              onLoad={() => setCarouselLoaded(true)}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block'
              }}
            />
            <div className="click-hint-overlay" style={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              padding: '8px 0',
              textAlign: 'center',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }}>
              <p style={{ margin: '0', fontSize: '13px', fontWeight: '500' }}>Tap to use this design</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

  // Use a dynamic baseUrl that works in both development and production
  const getBaseUrl = () => {
    // Check if we're running in a production environment
    if (window.location.hostname !== 'localhost') {
      console.log("Using production API endpoint");
      // Use Heroku backend URL
      // return 'https://bluedove-backend.herokuapp.com';
      return 'https://bluedovecreation-f12655b62b2c.herokuapp.com';
      
    } else {
      console.log("Using development API endpoint");
      // Use localhost for development
      return 'http://localhost:8000';
    }
  };

  const baseUrl = getBaseUrl();

  // const handleFontChange = (e) => {
  //   setFont(e.target.value);
  //   updatePrompt();
  // };
  const handleFontChange = (selectedOption) => {
    setFont(selectedOption ? selectedOption.value : '');
    updatePrompt(); 
  };
  

  // Combine all the values into a single prompt
  const updatePrompt = () => {
    let imagePrompt = '';
    let textPrompt = '';
    const fontStyle = fontStyleMapping[font] 
    ? fontStyleMapping[font] 
    : font; 
  

    if (page === 'hasBoth') {
      // 1. Always start with "An image of"
      // let imagePrompt = '';
      if (subject.trim() !== "") {
        // imagePrompt += An image of ${subject};
        imagePrompt += `A design of ${subject}`;
        // If details exist, append them after the subject:
        if (details.trim() !== "") {
          imagePrompt += ` ${details}`;
        }
      // } else {
        // If user didn't specify a subject, you could still default to "An image":
        // imagePrompt += An image; 
      }
    
      // 2. If backgroundColor is specified, add "… on a ____ background"
      if (backgroundColor.trim() !== "") {
        imagePrompt += ` on a ${backgroundColor} background`;
      }
    
      // 3. Now handle text portion (optional)
      // let textPrompt = '';
      if (text.trim() !== "") {
        // The text portion could read "with the text '…' …"
        textPrompt += ` with the text "${text}"`;
        // If textPosition was given, e.g. "positioned at the top"
        if (textposition.trim() !== "") {
          textPrompt += ` positioned ${textposition}`;
        }
        // If font is chosen, e.g. "written in bold Sans-Serif typeface"
        if (font.trim() !== "") {
          textPrompt += `, written in ${fontStyle} typeface`;
        }
        // If text color is chosen, e.g. "in red"
        if (fontColor.trim() !== "") {
          textPrompt += ` in ${fontColor}`;
        }
        // textPrompt += .; 
      }
    
        // End text part with a period or background context
        // if (backgroundColor.trim() !== "") {
        //   textPrompt += .;
        // } else {
        //   textPrompt += .;
        // }
    
        // Additional details
        if (additional.trim() !== "") {
          textPrompt += ` ${additional}`;
        }
      
    
      // Combine them: newPrompt = image part + text part
      let newPrompt = imagePrompt.trim();
      if (textPrompt) {
        newPrompt += textPrompt; // no extra space because textPrompt starts with " with…"
      }
      newPrompt = newPrompt.trim();
      setPrompt(newPrompt);
      console.log('Generated Prompt (hasBoth):', newPrompt);
    }
    
  
    // Handle Text-Related Inputs
    // if (page === 'hasText' || page === 'hasBoth') {
    if (page === 'hasText') {
      // let fontStyle1 = fontStyleMapping[font] || 'default font style';
      // let fontStyle2 = fontStyleMapping[extraFont] || 'default font style';

      const fontStyle1 = fontStyleMapping[font] 
      ? fontStyleMapping[font] 
      : font; 

      const fontStyle2 = fontStyleMapping[extraFont] 
      ? fontStyleMapping[extraFont] 
      : extraFont; 

      if (text.trim() !== "") {
        textPrompt += `A typography design with the text "${text}"`;
        // if (textposition.trim() !== "") {
        //   textPrompt +=  positioned ${textposition};
        // }
        if (font.trim() !== "") {
          textPrompt += `, written in ${fontStyle} typeface`;
        }
        if (fontColor.trim() !== "") {
          textPrompt += ` in ${fontColor}`;
        }
        // textPrompt += .;
      }
        // textPrompt += .; // End the text description with a period
        // Include backgroundColor only for 'hasText' page to avoid duplication in 'hasBoth'
      if (backgroundColor.trim() !== "") {
        textPrompt += ` on a ${backgroundColor} background.`;
      }
      
      if (extraText.trim() !== "") {
        textPrompt += ` And extra text "${extraText}"`;
        if (extraTextPosition.trim() !== "") {
          textPrompt += ` positioned ${extraTextPosition}`;
        }
        if (extraFont.trim() !== "") {
          textPrompt += `, written in ${fontStyle2} typeface`;
        }
        if (extraFontColor.trim() !== "") {
          textPrompt += ` in ${extraFontColor}`;
        }
        textPrompt += `.`;
      }
      
      if (additional.trim() !== "") {
        textPrompt += ` ${additional}`;
      }
    }
  
    // Combine Prompts Based on the Page
    let newPrompt = '';
    if (imagePrompt) {
      newPrompt += imagePrompt;
    }
    if (textPrompt) {
      newPrompt += ` ${textPrompt}`; // Add a space before textPrompt
    }
  
    // Clean up any extra spaces or commas
    newPrompt = newPrompt.trim().replace(/,$/, '');
  
    setPrompt(newPrompt);
    console.log('Generated Prompt:', newPrompt); // For debugging
  };
  
 

    // Use this effect to update the prompt whenever an input changes
  useEffect(() => {
      updatePrompt();
  }, [subject, details, text, textposition, font, fontColor, backgroundColor, additional, extraText, extraTextPosition, extraFont, extraFontColor]);

  // Update finalImage whenever image_url changes
  useEffect(() => {
    if (image_url && image_url !== "/") {
      setFinalImage(image_url);
      if (prompt) {
        setFinalPrompt(prompt);
      }
    }
  }, [image_url, prompt]);

  useEffect(() => {
    if (page !== 'initial') {
        setHistory(prevHistory => [...prevHistory, page]);
    }
  }, [page]);
  
  // useEffect(() => {
  //   // if (page !== 'initial') {
  //   if (history.length === 0 || history[history.length - 1] !== page) {
  //       setHistory(prevHistory => [...prevHistory, page]);
  //   }
  // }, [page]);
//   useEffect(() => {
//     setHistory(prevHistory => {
//         if (prevHistory.length === 0 || prevHistory[prevHistory.length - 1] !== page) {
//             console.log("✅ Adding to history:", page);
//             return [...prevHistory, page];
//         }
//         return prevHistory;
//     });
// }, [page]);


  // Initialize tshirtColor to 'white' when entering 'selectColor' page
  useEffect(() => {
    if (page === 'selectColor' && !tshirtColor) {
      setTshirtColor('white');
    }
  }, [page, tshirtColor]);

  // Handler for Design Type change
  const handleDesignTypeChange = (selectedOption) => {
    setDesignType(selectedOption);
    updatePrompt();
  };

// Define shared styles for react-select
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    maxWidth: '400px',
    margin: '0 auto 20px auto',
    borderRadius: '8px',
    borderColor: '#ccc',
    boxShadow: 'none',
    minHeight: '48px',
    height: '48px',
    '&:hover': {
      borderColor: '#999',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '48px',
    padding: '0 20px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '48px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1rem',
    color: '#999',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: state.data.fontFamily || 'Arial, sans-serif',
    fontSize: '1rem',
    color: '#333',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: state.data.fontFamily || 'Arial, sans-serif',
    fontSize: '1rem',
    backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
    color: '#333',
    padding: '12px 20px',
  }),
};

  console.log('Current path:', window.location.pathname);

  if (window.location.pathname === '/payment-success') {
    return (
      <div>
        <h1>Payment Successful!</h1>
        <p>Thank you for your order. Your payment has been processed successfully.</p>
      </div>
    );
  }

  const tshirtTemplates = {
    white: whiteTshirt,
    black: blackTshirt,
    darkgray: darkgrayTshirt,
    mediumblue: mediumblueTshirt,
    brickred: brickredTshirt,
    beanpaste: beanpasteTshirt,
    graygreen: graygreenTshirt,
    lightgray: lightgrayTshirt,
    brown: brownTshirt,
    lightapricot: lightapricotTshirt,
    rainwashed: rainwashedTshirt 
  };

  const colorOptions = [
    { name: 'White', color: 'white' },
    { name: 'Black', color: 'black' },
    { name: 'lightapricot', color: '#EEE8D5' },
    { name: 'lightgray', color: '#BEBEBE' },
    { name: 'mediumblue', color: '#639ACE ' },
    { name: 'beanpaste', color: '#C89FAE' },
    { name: 'brown', color: '#8B5A2B' },
    // { name: 'rainwashed', color: '#C5E0DC' },
    { name: 'graygreen', color: '#A9BDB0' },
    // { name: 'brickred', color: '#C72E2E' },
    { name: 'darkgray', color: '#808080' }
  ]

  // Array of size options
  const sizeOptions = ["XS", "S", "M", "L", "XL"];


  const goBack = () => {
    setHistory(prevHistory => {
        const updatedHistory = [...prevHistory];
        updatedHistory.pop(); 
        const lastPage = updatedHistory.pop(); 
        setPage(lastPage || 'initial'); 
        return updatedHistory;
    });
};

  // const goBack = () => {
  //     setHistory(prevHistory => {
  //       if (prevHistory.length === 0) return prevHistory;
  //       const updatedHistory = [...prevHistory];
  //       // updatedHistory.pop(); 
  //       const lastPage = updatedHistory.pop(); 
  //       // const lastPage = updatedHistory[updatedHistory.length - 1];
  //       // setPage(lastPage || 'initial'); 
  //       setPage(lastPage || 'hasBoth');
  //       return updatedHistory;
  //     });
  // };

//   const goBack = () => {
//     setHistory(prevHistory => {
//         if (prevHistory.length <= 1) {
//             setPage('hasBoth');
//             return [];
//         }

//         const updatedHistory = [...prevHistory];
//         updatedHistory.pop();
//         const lastPage = updatedHistory[updatedHistory.length - 1];

//         console.log("⬅️ Navigating back to:", lastPage);
//         setPage(lastPage);
//         window.scrollTo(0, 0); // Ensure the page scrolls up when navigating back

//         return updatedHistory;
//     });
// };

// const goBack = () => {
//   setTimeout(() => {
//       setHistory(prevHistory => {
//           if (prevHistory.length <= 1) {
//               setPage('hasBoth');
//               return [];
//           }

//           const updatedHistory = [...prevHistory];
//           updatedHistory.pop();
//           const lastPage = updatedHistory[updatedHistory.length - 1];

//           console.log("⬅️ Navigating back to:", lastPage);
//           setPage(lastPage);
//           window.scrollTo(0, 0); 

//           return updatedHistory;
//       });
//   }, 100);
// };



//   const goBack = () => {
//     setHistory(prevHistory => {
//         console.log("🔄 Before popping history:", prevHistory);

//         if (prevHistory.length <= 1) {
//             console.log("🔙 No history left, returning to 'hasBoth'");
//             setPage('hasBoth'); // Reset to first page
//             return [];
//         }

//         const updatedHistory = [...prevHistory];
//         updatedHistory.pop(); // Remove current page
//         const lastPage = updatedHistory[updatedHistory.length - 1]; // Get last page

//         console.log("⬅️ Navigating back to:", lastPage);
//         setPage(lastPage);

//         return updatedHistory;
//     });
// };


//   const goBack = () => {
//     setHistory(prevHistory => {
//         if (prevHistory.length <= 1) {
//             setPage('hasBoth'); // Default to the first page
//             return [];
//         }
//         const updatedHistory = [...prevHistory];
//         updatedHistory.pop(); // Remove current page
//         const lastPage = updatedHistory[updatedHistory.length - 1]; // Get the previous page
//         setPage(lastPage); // Navigate to the previous page
//         return updatedHistory;
//     });
// };
// const goBack = () => {
//   setHistory(prevHistory => {
//       if (prevHistory.length <= 1) {
//           console.log("Returning to the first page (hasBoth).");
//           setPage('hasBoth'); // Default to the first page
//           return [];
//       }

//       const updatedHistory = [...prevHistory];
//       updatedHistory.pop(); // Remove the current page
//       const lastPage = updatedHistory[updatedHistory.length - 1]; // Get the previous page

//       console.log("Navigating back to:", lastPage);
//       setPage(lastPage);
//       return updatedHistory;
//   });
// };



  const generateImage = async () =>{
    if (inputValue === "" && prompt ==="") {
        return;
    }
    setLoading(true)

    const requestPrompt = page === 'describe' ? inputValue : prompt;
    if (!requestPrompt) {
      console.error('Prompt is empty');
      setLoading(false);
      return;
    }
    // console.log('Prompt being sent to the API:', prompt);
    
    try {
      const response = await fetch(
        `${baseUrl}/api/generate-image`,
        // "https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image",
        // "http://localhost:8000/api/generate-image",  
        {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify({
                prompt: requestPrompt,  // Use the prompt state directly here
                model: 'V_2_TURBO',
            }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      console.log('API Response:', data); // Log the full response

      // Verify the structure of the response
      if (!data.data || data.data.length === 0) {
        throw new Error("Invalid response structure from generate-image API");
      }
      
      const generatedPrompt = data.data[0].prompt;
      const imageUrl = data.data[0].url;

      console.log('Generated Prompt:', generatedPrompt); // Log the generated prompt
      console.log('Generated Image URL:', imageUrl); // Log the image URL

      setGeneratedImage(imageUrl);
      setImage_url(imageUrl);
      setPrompt(generatedPrompt); // Store the original prompt
      setInputValue(generatedPrompt); // Optionally, update inputValue with the generated prompt
      
      // Save the generated image to the session
      if (sessionId) {
        await saveImageToSession(imageUrl, generatedPrompt);
      }
      
      // Save the generated image to sessionStorage
      const updatedImages = saveImageToStorage(imageUrl, generatedPrompt);
      setSavedImages(updatedImages);
      
    } catch (error){
      console.error('Error generating image:', error);
      alert(`Failed to generate image: ${error.message}`);
    } finally {
      setLoading(false)
    }
  };

  const handleClear = () => {
    // Clear out all relevant states
    setSubject('');
    setDetails('');
    setText('');
    setTextPosition('');
    setFont('');
    setFontColor('');
    setExtraText('');
    setExtraTextPosition('');
    setExtraFont('');
    setExtraFontColor('');
    setbackgroundColor('');
    setAdditional('');
    setPrompt(''); 
    setInputValue('');  // If you're also using inputValue for something
    setGeneratedImage(null);
    setImage_url('/');
    // Etc. anything else that should be cleared
  };
  

  const removeBackground = async () => {
    if (!image_url || image_url === "/") {
      alert("No image to process.");
      return;
    }
    
    console.log("Starting background removal process");
    setBgLoading(true);
    
    try {
      // If we're using a proxied URL, we need to extract the original URL
      let originalImageUrl = image_url;
      
      // Check if this is a proxied URL and extract the original URL
      if (image_url.includes('/api/proxy-image?url=')) {
        const urlParams = new URLSearchParams(image_url.split('?')[1]);
        originalImageUrl = urlParams.get('url');
        console.log('Extracted original URL for background removal:', originalImageUrl);
      }
      
      console.log("Sending request to remove-background API with URL:", originalImageUrl);
      
      // Construct the complete API endpoint URL
      const apiUrl = `${baseUrl}/api/remove-background`;
      console.log("Using API endpoint:", apiUrl);
      
      // Send request to remove-background API
      const removeBgResponse = await fetch(
        apiUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ imageUrl: originalImageUrl }), // Send the original image URL
        }
      );
  
      console.log("API Response status:", removeBgResponse.status);
      
      if (!removeBgResponse.ok) {
        const errorData = await removeBgResponse.json();
        console.error("API error response:", errorData);
        throw new Error(`Error removing background: ${errorData.detail || removeBgResponse.statusText}`);
      }
  
      const removeBgData = await removeBgResponse.json();
      console.log("API response data:", removeBgData);
  
      if (!removeBgData.transparentImage) {
        console.error("No transparent image in response");
        throw new Error("Background removal failed: No transparent image returned");
      }
  
      const transparentImageUrl = removeBgData.transparentImage;
      console.log('Transparent Image URL received:', transparentImageUrl);
  
      // Replace the original image with the background-removed image
      setImage_url(transparentImageUrl);
      console.log("Updated image_url with transparent image");
      
      // Automatically save the background-removed image
      console.log("Automatically saving background-removed image");
      const updatedImages = saveImageToStorage(transparentImageUrl, prompt);
      setSavedImages(updatedImages);
      
      // Also save to session if session exists
      if (sessionId) {
        await saveImageToSession(transparentImageUrl, prompt);
      }
      
      // Show feedback to user
      // alert("Background removed and image saved!");
  
    } catch (error) {
      console.error("Error removing background:", error);
      // More detailed error message for the user
      alert(`Error removing background: ${error.message}\nCheck console for more details.`);
    } finally {
      console.log("Background removal process completed");
      setBgLoading(false);
    }
  }
  
  // The saveImage function is no longer needed since we automatically update finalImage
  // whenever image_url changes using the useEffect hook
  
  const handleFeedback = async () => {
    if (!feedback) {
      alert('Please describe what you do not like about the image.');
      return;
    }   

    setLoading(true);

    try {
      const response = await fetch(
        `${baseUrl}/api/refine-prompt`,
        // 'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/refine-prompt', // this is heroku
        // 'http://localhost:8000/api/refine-prompt',  
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            originalPrompt: prompt,
            feedback: feedback,
          }),
        }
      ); 
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }    
      const data = await response.json();
      console.log('Refined Prompt data:', data); // Log the response data
      console.log(data.newPrompt); // Log the response data
      const newPrompt = data.newPrompt;    
      setPrompt(newPrompt);
      setInputValue(newPrompt);     
      // Now generate the image with the new prompt
      await generateImageWithNewPrompt(newPrompt);
    } catch (error) {
      console.error('Error refining prompt:', error);
    } finally {
      setLoading(false);
      setFeedback(''); // Clear the feedback
    }
  };
    
      // Helper function to generate image with the new prompt
  const generateImageWithNewPrompt = async (newPrompt) => {
    if (!newPrompt) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/generate-image`,
        // 'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/generate-image', // this is heroku
      //  'http://localhost:8000/api/generate-image',  
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              prompt: newPrompt,
              model: 'V_2_TURBO',
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      const originalImageUrl = data.data[0].url;
      
      // Create a proxied URL to avoid CORS issues
      const imageUrl = `${baseUrl}/api/proxy-image?url=${encodeURIComponent(originalImageUrl)}`;
      
      console.log('Original Image URL:', originalImageUrl);
      console.log('Proxied Image URL:', imageUrl);
  
      setGeneratedImage(imageUrl);
      setImage_url(imageUrl);
      setPrompt(newPrompt);
      setInputValue(newPrompt);
      
      // Save the generated image to the session
      if (sessionId) {
        await saveImageToSession(imageUrl, newPrompt);
      }
      
      // Save the generated image to sessionStorage
      const updatedImages = saveImageToStorage(imageUrl, newPrompt);
      setSavedImages(updatedImages);
      
    } catch (error) {
      console.error('Error generating image with new prompt:', error);
      alert(`Failed to generate image: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
      
  const orderTshirt = () => {
    if (!tshirtSize) {
      alert("Please select a size before placing an order.");
      return;
    }
    // Navigate to the order form page
    setPage('orderForm');
  };

  // New function to handle checkout after submitting the order
  const startCheckout = async (oid) => {
    // In a real scenario, dynamically set these line items based on product, size, etc.
    // Here we assume you have a Stripe Price ID or an item you want to charge for.
    // Replace "price_12345" with your actual price ID from Stripe.
    const lineItems = [
      { price: "price_1QV5UiHbIcYH0bIazTRP7cq9", quantity: 1, orderId: oid }, 
    ];
    try {
      const response = await fetch(
        `${baseUrl}/api/create-checkout-session`,
        //  'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/create-checkout-session', // this is heroku
        //  'http://localhost:8000/api/create-checkout-session', 
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ lineItems })
      });

      if (!response.ok) {
        throw new Error(`Error creating checkout session: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (data.url) {
        // Redirect to Stripe Checkout
        window.location.href = data.url;
      } else {
        alert("Failed to create checkout session.");
      }
    } catch (error) {
      console.error("Error starting checkout:", error);
    }
  }

  const submitOrder = async () => {
    // Check for empty fields
    if (!name || !email || !phone || !address || !city || !state || !zip) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }
    if (!usZipRegex.test(zip)) {
      setErrorMessage("Please enter a valid US ZIP code.");
      return;
    }
    const fullAddress = `${address}, ${city}, ${state}, ${zip}`;


    setErrorMessage(''); // Clear any previous error message
    // Add order submission logic here
    const orderDetails = {
      name,
      email,
      phone,
      address: fullAddress, // or keep them separate in DB
      tshirtColor,
      tshirtSize,
      finalImage,
      finalPrompt
    };

    try {
      // Create the order in our backend
      const orderResponse = await fetch(
        `${baseUrl}/api/orders`,
        // 'https://bluedovecreation-f12655b62b2c.herokuapp.com/api/orders', // this is heroku
        // 'http://localhost:8000/api/orders',
        {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails),
      });

      if (!orderResponse.ok) {
        throw new Error(`Error creating order: ${orderResponse.statusText}`);
      }

      const orderData = await orderResponse.json();
      console.log("Order created:", orderData.orderId, orderData.imageUrl);

      // Now proceed to create a Stripe checkout session
      setOrderId(orderData.orderId);
      await startCheckout(orderData.orderId);
    } catch (error) {
      console.error("Error submitting order:", error);
      alert("Error placing order. Please try again.");
    }
  };

  // Function to create a new session
  const createNewSession = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/create-temp-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Created new session:', data);
      
      // Save the session ID and expiry in localStorage
      localStorage.setItem('tempSessionId', data.session_id);
      localStorage.setItem('tempSessionExpiry', data.expires_at);
      
      // Update state
      setSessionId(data.session_id);
      setSessionExpiry(data.expires_at);
    } catch (error) {
      console.error('Error creating session:', error);
    }
  };
  
  // Function to fetch saved images for a session
  const fetchSessionImages = async (sid) => {
    try {
      const response = await fetch(`${baseUrl}/api/get-session-images?session_id=${sid}`);
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Fetched session images:', data);
      
      // Update state with the saved images
      setSavedImages(data.images || []);
    } catch (error) {
      console.error('Error fetching session images:', error);
    }
  };
  
  // Function to save an image to the session
  const saveImageToSession = async (imageUrl, promptText) => {
    if (!sessionId || !imageUrl || !promptText) {
      console.error('Missing required data for saving image');
      return;
    }
    
    try {
      const response = await fetch(`${baseUrl}/api/save-image-to-session?session_id=${sessionId}&image_url=${encodeURIComponent(imageUrl)}&prompt=${encodeURIComponent(promptText)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Saved image to session:', data);
      
      // Refresh the list of saved images
      fetchSessionImages(sessionId);
    } catch (error) {
      console.error('Error saving image to session:', error);
    }
  };
  
  // Function to toggle the saved images display
  const toggleSavedImages = () => {
    console.log("toggleSavedImages called, current state:", showSavedImages);
    
    if (!showSavedImages) {
      // Refresh the images when opening the panel
      const storedImages = getSavedImages();
      console.log("Retrieved saved images:", storedImages);
      setSavedImages(storedImages);
    }
    
    // Toggle the panel visibility
    setShowSavedImages(!showSavedImages);
    console.log("Panel visibility set to:", !showSavedImages);
  };
  
  // Function to load a saved image
  const loadSavedImage = (image) => {
    setGeneratedImage(image.image_url);
    setImage_url(image.image_url);
    setPrompt(image.prompt);
    setInputValue(image.prompt);
    setShowSavedImages(false);
  };

  // Function to clear all saved images
  const clearAllSavedImages = () => {
    clearSavedImages();
    setSavedImages([]);
  };

  return (
    <div className='ai-image-generator'>

      <header className="logo-header">
        {/* <img 
          src={bluedove} 
          alt="Company Logo" 
          className="logo-image" 
        /> */}
        <Link to="/" style={{ textDecoration: "none" }}>
                        <h1 className="logo-text">Bluedove</h1>
                    </Link>
      </header>

      {(page === 'hasBoth' || page === 'hasText') && (
          <div className="examples-section">
            <div className="section">
              <h2>Get Inspiration and Start Creating Your T-shirt</h2>
              <div className="design-row">
                <div className="category-tabs category-tabs-styled">
                  <div className="main-tabs">
                    <div 
                      className={`category-tab ${page === 'hasBoth' ? 'active' : ''}`} 
                      onClick={() => {
                        setPage('hasBoth');
                        // Replace direct style manipulation with class toggling
                        // document.querySelector('.category-tab:first-child').classList.add('active');
                        // document.querySelector('.category-tab:last-child').classList.remove('active');
                      }}
                      title="Visual or illustrated designs, sometimes with minimal text"
                    >
                      Images
                    </div>
                    <div 
                      className={`category-tab ${page === 'hasText' ? 'active' : ''}`} 
                      onClick={() => {
                        setPage('hasText');
                        // Replace direct style manipulation with class toggling
                        // document.querySelector('.category-tab:last-child').classList.add('active');
                        // document.querySelector('.category-tab:first-child').classList.remove('active');
                      }}
                      title="Primarily text-based designs, emphasizing fonts and layout"
                    >
                      Typography
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}

      {page === 'hasBoth' && (
        <div className="design-container">
          <div className="left-column1">
            <h2 className="carousel-title">Examples</h2>
            {/* <div className="carousel-container" style={{ width: '100%', maxWidth: '1000px', margin: '0 auto' }}> */}
            <div className="carousel-container">
              {!visibleCarousel && (
                <div className="carousel-placeholder" style={{ 
                  height: '300px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  border: '1px solid #eee',
                  borderRadius: '8px' 
                }}>
                  <div className="loading-spinner"></div>
                </div>
              )}
              
              {visibleCarousel && (
                <>
                {/* Add a clear instruction above the carousel */}
                <div className="example-instruction">
                  👆 Tap any example to see its design prompt
                </div>
                <Slider {...settings}>
                  {artisticExamples.map((item) => (
                    <div 
                      key={item.id} 
                      className="carousel-slide clickable-slide" 
                      onClick={() => handleImageClick(item)}
                      style={{ 
                        margin: '0 8px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        position: 'relative',
                        cursor: 'pointer'
                      }}
                    >
                      <img 
                        src={item.image} 
                        alt={item.label} 
                        loading="lazy"
                        onLoad={() => setCarouselLoaded(true)}
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block'
                        }}
                      />
                      
                      {/* <div className="click-hint-overlay" style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        padding: '8px 0',
                        textAlign: 'center',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px'
                      }}>
                        <p style={{ margin: '0', fontSize: '13px', fontWeight: '500' }}>Tap to use this design</p>
                      </div> */}
                    </div>
                  ))}
                </Slider>
                </>
              )}
            </div>
          </div>

          <div className="right-column1"> 
            <div className="prompt-col">
              <div className="prompt-generator">   
                <div className="tag-container">
                  
                  <div className="tag-input">
                    <label htmlFor="subject">Subject</label>
                    <input 
                        type="text" 
                        placeholder="ex) a cat, a president, etc" 
                        value={subject} 
                        onChange={(e) => { setSubject(e.target.value); updatePrompt(); }} 
                    />
                  </div>
                  <div className="tag-input">
                    <label htmlFor="details">Details</label>
                    <input 
                        type="text" 
                        placeholder="ex) with bright blue eyes, wearing a suit, etc" 
                        value={details} 
                        onChange={(e) => { setDetails(e.target.value); updatePrompt(); }}
                    />
                  </div>

                  <div className="tag-input">
                    <label htmlFor="background color">Background Color</label> 
                    <input 
                        type="text" 
                        placeholder="ex) white, grey, black, etc" 
                        value={backgroundColor} 
                        onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                    />
                  </div>
                  {/* <div className="tag-input-text"> */}

                  <div className="tag-input"> 
                    <label htmlFor="text">Text if any</label> 
                    <input 
                        type="text" 
                        placeholder="ex) I am happy, God loves you, etc" 
                        value={text} 
                        onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                    />
                  </div>
                    {/* <div className="tag-container-inline"> */}

                  {text.trim() !== "" && (
                    <div className="additional-text-options">  

                      <div className="tag-input">
                        <label htmlFor="textposition">Text Position</label> 
                        <input 
                            type="text" 
                            placeholder="ex) centered, below the logo, etc" 
                            value={textposition} 
                            onChange={(e) => { setTextPosition(e.target.value); updatePrompt(); }} 
                        />
                      </div>

                      <div className="tag-input">
                        <label htmlFor="font">Font</label> 
                          <CreatableSelect
                          id="font"
                          className="select-font"
                          value={
                            // Must match the shape { value, label } or be null
                            font 
                              ? { value: font, label: font } 
                              : null
                          }
                          // Provide your existing built-in options
                          options={fontOptions} 
                          onChange={(newValue) => {
                            // newValue will be { value, label } if user selected or created
                            if (newValue) {
                              setFont(newValue.value);
                            } else {
                              setFont('');
                            }
                          }}
                          // If user types something not in the list, react-select/creatable will create it
                          // isClearable if you want a clear button
                          isClearable
                          placeholder="Type or select a font..."
                          styles={customSelectStyles}
                        />
                      </div>
                      <div className="tag-input">
                        <label htmlFor="text color">Text Color</label> 
                        <input 
                            type="text" 
                            placeholder="ex) black, blue, red, etc" 
                            value={fontColor} 
                            onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                        />
                      </div>
                    </div>
                  )}    
                  <div className="tag-input-additional">
                  {/* <div className="tag-input"> */}
                    <label htmlFor="additional description">Additional Description</label> 
                    <input 
                        type="text" 
                        placeholder="Additional description" 
                        value={additional} 
                        onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                    />
                  </div>
                  
                </div>
              </div>
              
              <div className="prompt-display">
                <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} rows="4" />
              </div>

              <div className="button-row">
                {/* Generate button */}
                <button 
                  className="generate-btn"
                  onClick={generateImage}
                  disabled={loading || (page === 'describe' ? !inputValue : !prompt)}
                >
                  {loading ? "Generating..." : "Generate"}
                </button>
                {/* Clear button */}
                <button className="clear-btn" onClick={handleClear}>
                  Clear
                </button>
              </div>

              <div className="img-loading">
                {/* Old loading indicator removed - now displayed directly on t-shirt preview */}
                {/* Image hidden since it's already shown on t-shirt preview */}
                {/* <div className="image">
                  <img src={image_url==="/"?background5:image_url} alt="Generated Design"/>
                </div> */}
              </div>
            </div>

            <div className="preview-col">
              <div className="select-color-container">
                {/* Row #1: T-shirt preview */}
                <div className="tshirt-row">
                  <div className="tshirt-preview-container">
                    <div 
                      className="tshirt-preview" 
                      style={{ backgroundImage: `url(${tshirtTemplates[tshirtColor]})` }}
                    >
                      <img 
                        src={finalImage || (image_url !== "/" ? image_url : background6)} 
                        alt="T-shirt design" 
                        className="tshirt-image"
                      />
                      {loading && (
                        <div className="bg-removal-loading">
                          <div className="img-generation-loading-text">Generating Image...</div>
                          <div className="img-generation-loading-bar">
                            <div className="img-generation-loading-bar-inner"></div>
                          </div>
                        </div>
                      )}
                      {bgLoading && (
                        <div className="bg-removal-loading">
                          <div className="bg-removal-loading-text">Removing Background...</div>
                          <div className="bg-removal-loading-bar">
                            <div className="bg-removal-loading-bar-inner"></div>
                          </div>
                        </div>
                      )}
                      <button
                        className="remove-bg-floating-btn"
                        onClick={removeBackground}
                        disabled={image_url === "/" || bgLoading || loading} 
                        title="Remove Background"
                      >
                        {bgLoading ? "Processing..." : "✂️ Remove Background"}
                      </button>
                    </div>
                    <div className="saved-images-button-container">
                      <button
                        className={`view-saved-btn  ${showSavedImages ? 'active' : ''}`}
                        onClick={toggleSavedImages}
                      >
                        View Saved Designs
                      </button>
                    </div>
                  </div>
                </div>

                {/* Row #2: Color */}
                <div className="color-picker">
                  <div className="select-tshirt-header">T-shirt Color:</div>
                  <div className="color-options">
                    {colorOptions.map((option) => (
                      <button
                        key={option.name}
                        style={{ backgroundColor: option.color }}
                        className={`color-option ${
                          tshirtColor === option.name.toLowerCase() ? 'selected' : ''
                        }`}
                        onClick={() => setTshirtColor(option.name.toLowerCase())}
                        aria-label={option.name}
                      >
                        {tshirtColor === option.name.toLowerCase() && <span className="checkmark">✔</span>}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Row #3: Size */}
                <div className="size-picker">
                  <div className="select-size-header">T-shirt Size:</div>
                  <div className="size-options">
                    {sizeOptions.map((size) => (
                      <button
                        key={size}
                        className={`size-option ${tshirtSize === size ? "selected" : ""}`}
                        onClick={() => setTshirtSize(size)}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
                
                {/* Row #4: Order button */}
                <div className="order-button-wrapper">
                  <div className="order-btn-container">
                    <button className="order-btn" onClick={orderTshirt}>Order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      )}

      {page === 'hasText' && (
        <div className="design-container">
          <div className="left-column1">
            <h2 className="carousel-title"> Examples</h2>
            <div className="carousel-container" style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
              {!visibleCarousel && (
                <div className="carousel-placeholder" style={{ 
                  height: '300px', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  border: '1px solid #eee',
                  borderRadius: '8px' 
                }}>
                  <div className="loading-spinner"></div>
                </div>
              )}
              
              {visibleCarousel && (
                <>
                {/* Add a clear instruction above the carousel */}
                <div className="example-instruction" >
                  👆 Tap any example to see its design prompt
                </div>
                <Slider {...settings}>
                  {typographyExamples.map((item) => (
                    <div 
                      key={item.id} 
                      className="carousel-slide clickable-slide" 
                      onClick={() => handleImageClick(item)}
                      style={{ 
                        margin: '0 8px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        position: 'relative',
                        cursor: 'pointer'
                      }}
                    >
                      <img 
                        src={item.image} 
                        alt={item.label} 
                        loading="lazy"
                        onLoad={() => setCarouselLoaded(true)}
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block'
                        }}
                      />
{/*                       
                      <div className="click-hint-overlay" style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        padding: '8px 0',
                        textAlign: 'center',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px'
                      }}>
                        <p style={{ margin: '0', fontSize: '13px', fontWeight: '500' }}>Tap to use this design</p>
                      </div> */}
                    </div>
                  ))}
                </Slider>
                </>
              )}
            </div>
          </div>

          <div className="right-column1">
            <div className="prompt-col">
              <div className="prompt-generator">
                <div className="tag-container">
                    <div className="tag-input">
                    <label htmlFor="text">Text</label> 
                        <input 
                            type="text" 
                            placeholder="ex) I am happy, God loves you, etc" 
                            value={text} 
                            onChange={(e) => { setText(e.target.value); updatePrompt(); }}
                        />
                    </div>
                    {/* <div className="tag-container-inline"> */}
                    <div className="tag-input">
                    {/* <label htmlFor="font">Font</label> 
                        <input 
                            type="text" 
                            placeholder="ex) bold arial, italic roboto, etc" 
                            value={font} 
                            onChange={(e) => { setFont(e.target.value); updatePrompt(); }} 
                        /> */}
                        <label htmlFor="font">Font</label> 
                      {/* <Select
                        id="font"
                        className="select-font"
                        value={fontOptions.find(option => option.value === font)}
                        // onChange={handleFontChange}
                        onChange={(selected) => {
                          setFont(selected ? selected.value : '');
                          updatePrompt();
                        }}
                        options={fontOptions}
                        placeholder="Select a font..."
                        components={{ Option: CustomOption }} // Integrate CustomOption
                        styles={{ customSelectStyles}}
                        /> */}
                      <CreatableSelect
                        id="font"
                        className="select-font"
                        value={
                          // Must match the shape { value, label } or be null
                          font 
                            ? { value: font, label: font } 
                            : null
                        }
                        // Provide your existing built-in options
                        options={fontOptions} 
                        onChange={(newValue) => {
                          // newValue will be { value, label } if user selected or created
                          if (newValue) {
                            setFont(newValue.value);
                          } else {
                            setFont('');
                          }
                        }}
                        // If user types something not in the list, react-select/creatable will create it
                        // isClearable if you want a clear button
                        isClearable
                        placeholder="Type or select a font..."
                        styles={customSelectStyles}
                      />
                    </div>
                    <div className="tag-input">
                    <label htmlFor="text color">Text Color</label> 
                        <input 
                            type="text" 
                            placeholder="ex) black, blue, red, etc" 
                            value={fontColor} 
                            onChange={(e) => { setFontColor(e.target.value); updatePrompt(); }}
                        />
                    </div>
                    <div className="tag-input">
                    <label htmlFor="background color">Background Color</label> 
                        <input 
                            type="text" 
                            placeholder="Background Color" 
                            value={backgroundColor} 
                            onChange={(e) => { setbackgroundColor(e.target.value); updatePrompt(); }}
                        />
                    </div>

                    <div className="tag-input">
                      <label htmlFor="extraText">Additional Text if any</label>
                      <input
                        type="text"
                        placeholder="ex) a second line, more words..."
                        value={extraText}
                        onChange={(e) => { setExtraText(e.target.value); updatePrompt(); }}
                      />
                    </div>

                    {extraText.trim() !== "" && (

                      <div className="additional-text-options">

                        <div className="tag-input">
                          <label htmlFor="textposition">Text Position</label> 
                          <input 
                            type="text"
                            placeholder="ex) centered, below the main text, etc" 
                            value={extraTextPosition}
                            onChange={(e) => { setExtraTextPosition(e.target.value); updatePrompt(); }}
                          />
                        </div>
                        <div className="tag-input">
                          <label htmlFor="font">Font</label> 
                           <CreatableSelect
                              id="extraFont"
                              className="select-font"
                              value={
                                // Must match the shape { value, label } or be null
                                extraFont 
                                  ? { value: extraFont, label: extraFont } 
                                  : null
                              }
                              // Provide your existing built-in options
                              options={fontOptions} 
                              onChange={(newValue) => {
                                // newValue will be { value, label } if user selected or created
                                if (newValue) {
                                  setExtraFont(newValue.value);
                                } else {
                                  setExtraFont('');
                                }
                              }}
                              // If user types something not in the list, react-select/creatable will create it
                              // isClearable if you want a clear button
                              isClearable
                              placeholder="Type or select a font..."
                              styles={customSelectStyles}
                            />
        
                          {/* </select> */}
                        </div>
                        <div className="tag-input">
                          <label htmlFor="text color">Text Color</label> 
                          <input 
                              type="text" 
                              placeholder="ex) black, blue, red, etc" 
                              value={extraFontColor} 
                              onChange={(e) => { setExtraFontColor(e.target.value); updatePrompt(); }}
                          />
                        </div>
                        {/* You may also let them pick a second font, color, etc. if you want. 
                            Or reuse the same font input, etc. */}
                      </div>
                    )}
                    
                    <div className="tag-input-additional">
                    <label htmlFor="additional description">Additional Description</label> 
                        <input 
                            type="text" 
                            placeholder="Additional description" 
                            value={additional} 
                            onChange={(e) => { setAdditional(e.target.value); updatePrompt(); }}
                        />
                    </div>
                </div>
              </div>

              <div className="prompt-display">
                <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} rows="4" />
              </div>

              <div className="button-row">
                <button 
                  className="generate-btn"
                  onClick={generateImage}
                  disabled={loading || (page === 'describe' ? !inputValue : !prompt)}
                >
                  {loading ? "Generating..." : "Generate"}
                </button>
                <button className="clear-btn" onClick={handleClear}>
                  Clear
                </button>
              </div>

              <div className="img-loading">
                {/* Old loading indicator removed - now displayed directly on t-shirt preview */}
                {/* Image hidden since it's already shown on t-shirt preview */}
                {/* <div className="image">
                  <img src={image_url==="/"?background5:image_url} alt="Generated Design"/>
                </div> */}
              </div>

            </div>

            <div className="preview-col">
              <div className="select-color-container">
                {/* Row #1: T-shirt preview */}
                <div className="tshirt-row">
                  <div className="tshirt-preview-container">
                    <div 
                      className="tshirt-preview" 
                      style={{ backgroundImage: `url(${tshirtTemplates[tshirtColor]})` }}
                    >
                      <img 
                        src={finalImage || (image_url !== "/" ? image_url : background6)} 
                        alt="T-shirt design" 
                        className="tshirt-image"
                      />
                      {loading && (
                        <div className="bg-removal-loading">
                          <div className="img-generation-loading-text">Generating Image...</div>
                          <div className="img-generation-loading-bar">
                            <div className="img-generation-loading-bar-inner"></div>
                          </div>
                        </div>
                      )}
                      {bgLoading && (
                        <div className="bg-removal-loading">
                          <div className="bg-removal-loading-text">Removing Background...</div>
                          <div className="bg-removal-loading-bar">
                            <div className="bg-removal-loading-bar-inner"></div>
                          </div>
                        </div>
                      )}
                      <button
                        className="remove-bg-floating-btn"
                        onClick={removeBackground}
                        disabled={image_url === "/" || bgLoading || loading} 
                        title="Remove Background"
                      >
                        {bgLoading ? "Processing..." : "✂️ Remove Background"}
                      </button>
                    </div>
                    <div className="saved-images-button-container">
                      <button
                        className={`view-saved-btn  ${showSavedImages ? 'active' : ''}`}
                        onClick={toggleSavedImages}
                      >
                        View Saved Designs
                      </button>
                    </div>
                  </div>
                </div>

                {/* Row #2: Color */}
                <div className="color-picker">
                  <div className="select-tshirt-header">T-shirtColor:</div>
                  <div className="color-options">
                    {colorOptions.map((option) => (
                      <button
                        key={option.name}
                        style={{ backgroundColor: option.color }}
                        className={`color-option ${
                          tshirtColor === option.name.toLowerCase() ? 'selected' : ''
                        }`}
                        onClick={() => setTshirtColor(option.name.toLowerCase())}
                        aria-label={option.name}
                      >
                        {tshirtColor === option.name.toLowerCase() && <span className="checkmark">✔</span>}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Row #3: Size */}
                <div className="size-picker">
                  <div className="select-size-header">T-shirt Size:</div>
                  <div className="size-options">
                    {sizeOptions.map((size) => (
                      <button
                        key={size}
                        className={`size-option ${tshirtSize === size ? "selected" : ""}`}
                        onClick={() => setTshirtSize(size)}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
                
                {/* Row #4: Order button */}
                <div className="order-button-wrapper">
                  <div className="order-btn-container">
                    <button className="order-btn" onClick={orderTshirt}>Order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {page === 'orderForm' && (
        <>
          <button className="back-btn" onClick={goBack}>
            ← Back
          </button>
          {/* <h2 className="order-form-heading">Enter Your Order Details</h2> */}
          <div className="order-form">
            {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            
            {/* Name, email, phone... */}
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input 
                id="name" 
                type="text" 
                value={name} 
                onChange={(e)=>setName(e.target.value)} 
                placeholder="Your full name"/>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input 
                id="email" 
                type="email" 
                value={email} 
                onChange={(e)=>setEmail(e.target.value)} 
                placeholder="Your email"/>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input 
                id="phone" 
                type="text" 
                value={phone} 
                onChange={(e)=>setPhone(e.target.value)} 
                placeholder="Your phone number"/>
            </div>
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input
                id="address"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Street address, apt, suite, etc."
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                id="city"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Los Angeles"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input
                id="state"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="CA"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="zip">Zip:</label>
              <input
                id="zip"
                type="text"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="90001"
                required
              />
            </div>
            
            <div className="order-btn-container">
              <button className="order-btn" onClick={submitOrder}>
                Proceed to Payment
              </button>
            </div>
          </div>
        </>
      )}
      
      {/* Saved Images Panel */}
      {showSavedImages && (
        <>
          <div className="saved-images-overlay" onClick={() => setShowSavedImages(false)}></div>
          <div className="saved-images-panel">
            <h3>Your Saved Images</h3>
            <p className="note">Images will be available until you close this browser tab</p>
            
            {savedImages.length === 0 ? (
              <p>No saved images yet. Generate some images to see them here!</p>
            ) : (
              <>
                <div className="saved-images-grid">
                  {savedImages.map((image) => (
                    <div key={image.image_id} className="saved-image-item">
                      <img 
                        src={image.image_url} 
                        alt={image.prompt} 
                        onClick={() => loadSavedImage(image)}
                      />
                      <p className="saved-image-prompt">{image.prompt}</p>
                      <p className="saved-image-date">
                        {new Date(image.created_at).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </div>
                
                {/* Replace button row div with our new saved-images-buttons div container */}
                <div className="saved-images-buttons">
                  <button 
                    onClick={clearAllSavedImages}
                    className="clear-all-btn"
                  >
                    Clear Images
                  </button>
                  
                  <button 
                    onClick={() => setShowSavedImages(false)}
                    className="close-btn"
                  >
                    Close
                  </button>
                </div>
              </>
            )}
            
            {/* Remove the standalone Close button since it's now in the saved-images-buttons container */}
            {!savedImages.length && (
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: '100%',
                padding: '20px 0'
              }}>
                <button 
                  className="close-button" 
                  onClick={toggleSavedImages}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#333',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                  }}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default ImageGenerator